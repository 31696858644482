<template>
  <v-app>
    <v-main>
      <v-container fluid class="mt-5">
        <v-row justify="center">
          <v-col cols="12" md="11" lg="10">
            <v-card class="elevation-10">
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <slot></slot>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {

}
</script>
