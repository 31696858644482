<template>
  <v-card tile color="grey darken-4">
    <v-subheader>Ausgewählte Projekte</v-subheader>
    <v-slide-y-transition class="py-0" group>
      <v-list-item dense v-for="(project, index) in selectedProjects" :key="project.slug">
        <v-list-item-content>
          <v-list-item-title>{{ project.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="$emit('remove', index)">
            <v-icon size="18" color="grey lighten-1">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
export default {
  props: [
    'selectedProjects'
  ]
}
</script>
