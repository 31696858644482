<template>
  <v-card class="mt-10" tile color="grey darken-4">
    <v-subheader class="pt-2">Auswahl speichern</v-subheader>
    <v-container class="py-0 px-4">
      <v-row>
        <v-col cols="9">
          <v-text-field label="Name" height="32" :disabled="selectedProjects.length < 1" dense color="teal darken-3" v-model="selectionName"></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn @click="$emit('add', selectionName)" :disabled="!selectionName" color="blue-grey darken-4" class="mt-2" small><v-icon small>mdi-content-save</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-divider v-if="savedSelections.length > 0"></v-divider>
    <v-list color="grey darken-4" v-if="savedSelections.length > 0" :disabled="isLoading">
      <v-subheader>gespeicherte Auswahl</v-subheader>
      <v-list-item-group>
        <v-list-item @click="$emit('apply', selection.name)" dense v-for="(selection, index) in savedSelections" :key="selection.time">
          <v-list-item-content>
            <v-list-item-title
              >{{ selection.name }} <span class="caption grey--text ml-1">{{ timeAgo(selection.time) }}</span></v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon @click="$emit('remove', index)" size="18" color="grey lighten-1">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { format, register } from 'timeago.js'
import de from '@/lang/de'

register('de_DE', de)
export default {
  props: ['selectedProjects', 'savedSelections', 'isLoading'],
  data: () => ({
    selectionName: null
  }),
  methods: {
    timeAgo (time) {
      return format(time, 'de_DE')
    }
  }
}
</script>
