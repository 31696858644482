<template>
  <base-layout>
    <main-layout>
      <grid :isLoading="isLoading" :searchTerms="searchTerms">
        <template #search>
          <v-combobox class="hs-combobox" solo background-color="blue-grey darken-4" color="blue-grey lighten-3" item-color="blue-grey lighten-3" dense multiple prepend-inner-icon="mdi-magnify" deletable-chips clearable small-chips :loading="isLoading" :items="searchTerms" v-model="searchInput"></v-combobox>
        </template>
        <template #grid>
          <v-col v-for="project in filteredProjects" :key="project.slug" class="d-flex child-flex pa-2" cols="4" lg="3">
            <grid-image :project="project" :isSelected="selectedProjects.find(i => i.title === project.title) ? true : false" @add-to-selected-projects="addToSelectedProjects(project)" @remove-from-selected-projects="removeFromSelectedProjects(selectedProjects.findIndex(i => i.slug === project.slug))"></grid-image>
          </v-col>
        </template>
      </grid>
    </main-layout>
    <sidebar-layout>
      <card-selection :selectedProjects="selectedProjects" @remove="removeFromSelectedProjects"></card-selection>
      <card-textarea :appliedText="this.text.value" :appliedTextShow="this.text.show" @text-change="changeText" @text-show="changeText"></card-textarea>
      <v-btn @click="copyToClipboard" :loading="creatingTinyUrl" class="mt-5 mr-3" color="blue-grey darken-4" :disabled="selectedProjects.length < 1"><v-icon small class="mr-2">mdi-content-copy</v-icon>Link kopieren</v-btn>
      <v-btn @click="openLinkInNewTab" class="mt-5" color="grey darken-4" :disabled="selectedProjects.length < 1"><v-icon small class="mr-2">mdi-open-in-new</v-icon>in neuen Tab öffnen</v-btn>
      <card-save :selectedProjects="selectedProjects" :isLoading="isLoading" :savedSelections="savedSelections" @add="addToSavedSelections" @apply="applySavedSelection" @remove="removeFromSavedSelections"></card-save>
    </sidebar-layout>
    <v-snackbar v-model="snackbar.show" color="blue-grey darken-4" :timeout="snackbar.timeout">
      {{ snackbar.text }}
    </v-snackbar>
  </base-layout>
</template>

<script>
import { version } from '../package.json'
import BaseLayout from '@/layouts/BaseLayout'
import MainLayout from '@/layouts/MainLayout'
import SidebarLayout from '@/layouts/SidebarLayout'

import GridImage from '@/components/GridImage'
import Grid from '@/components/Grid'
import CardSelection from '@/components/CardSelection'
import CardSave from '@/components/CardSave'
import CardTextarea from '@/components/CardTextarea'

import axios from 'axios'
import TinyURL from 'tinyurl'

export default {
  name: 'App',
  components: {
    GridImage,
    Grid,
    BaseLayout,
    MainLayout,
    SidebarLayout,
    CardSelection,
    CardSave,
    CardTextarea
  },
  methods: {
    addToSelectedProjects (project, index) {
      if (this.selectedProjects.includes(project)) return
      this.selectedProjects.push(project)
    },
    removeFromSelectedProjects (index) {
      this.selectedProjects.splice(index, 1)
    },
    removeFromSavedSelections (index) {
      this.savedSelections.splice(index, 1)
    },
    async copyToClipboard () {
      const tempInput = document.createElement('input')
      // tempInput.value = this.shareLink
      tempInput.value = await this.shortUrl(this.shareLink)
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      this.showSnackbar('Link erfolgreich in Zwischenablage kopiert')
    },
    openLinkInNewTab () {
      window.open(this.shareLink, '_blank')
    },
    addToSavedSelections (name) {
      console.log('add to saved')
      this.savedSelections.push({
        time: Date.now(),
        name,
        projects: JSON.parse(JSON.stringify(this.selectedProjects)),
        text: JSON.parse(JSON.stringify(this.text))
      })
      this.showSnackbar(`Auswahl wurde im Browser gespeichert als '${name}'.`, 6000)
    },
    applySavedSelection (name) {
      this.selectedProjects = []
      const selectedSavedSelection = JSON.parse(JSON.stringify(this.savedSelections)).find(selection => selection.name === name)
      console.log(selectedSavedSelection)
      this.selectedProjects = selectedSavedSelection.projects
      this.text = selectedSavedSelection.text
      this.showSnackbar(`Auswahl '${name}' geladen.`)
    },
    async getProjects () {
      this.isLoading = true
      try {
        const { data } = await axios({
          url: process.env.VUE_APP_WPGRAPHQL,
          method: 'post',
          data: {
            query: `
                query {
                  projects(where: { orderby: { field: DATE, order: DESC } }, first: 100) {
                    nodes {
                      title
                      featuredImage {
                        node {
                          sourceUrl(size: GRID_THUMBNAIL)
                        }
                      }
                      categories {
                        nodes {
                          name
                        }
                      }
                      tags {
                        nodes {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              `
          }
        })
        this.projects = data.data.projects.nodes
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    showSnackbar (text, timeout = 3000) {
      this.snackbar.show = true
      this.snackbar.timeout = timeout
      this.snackbar.text = text
    },
    changeText (value, textValue) {
      this.text.show = value
      this.text.value = textValue
    },
    async shortUrl (url) {
      let shortUrl = url
      this.creatingTinyUrl = true
      try {
        const res = await TinyURL.shorten(url)
        shortUrl = res.toLowerCase().includes('error') ? shortUrl : res
      } catch (err) {
      }
      this.creatingTinyUrl = false
      return shortUrl
    }
  },
  data: () => ({
    searchInput: [],
    selectedProjects: [],
    savedSelections: [],
    selectionName: null,
    isLoading: false,
    projects: [],
    snackbar: {
      show: false,
      text: '',
      timeout: 4000
    },
    text: {
      show: null,
      value: ''
    },
    creatingTinyUrl: false
  }),
  mounted () {
    const localStorageName = 'savedSelections-' + version
    if (localStorage.getItem(localStorageName)) {
      this.savedSelections = JSON.parse(localStorage[localStorageName])
    }
    this.getProjects()
  },
  watch: {
    savedSelections () {
      localStorage.setItem(`${'savedSelections-' + version}`, JSON.stringify(this.savedSelections))
    }
  },
  computed: {
    filteredProjects () {
      if (this.searchInput.length > 0) {
        return this.projects.filter(project => this.searchInput.some(inputValue => project.title.includes(inputValue)) || this.searchInput.some(inputValue => project.categories.nodes.some(cat => cat.name.includes(inputValue))) || this.searchInput.some(inputValue => project.tags.nodes.some(tag => tag.name.includes(inputValue))))
      }
      return this.projects
    },
    searchTerms () {
      let terms = []
      if (this.projects) {
        this.projects.forEach(project => {
          terms.push(project.title)
          terms.push(project.categories.nodes.map(cat => cat.name))
          terms.push(project.tags.nodes.map(tag => tag.name))
        })
        terms = terms.flat().sort((a, z) => a.localeCompare(z))
        terms = [...new Set(terms)]
      }
      return terms
    },
    shareLink () {
      const link = `${process.env.VUE_APP_WPURL}${process.env.VUE_APP_PROJECT_SHARE_PAGE}/?projects=${this.selectedProjects.map(project => project.slug)}${this.text.show && this.text.value !== 'null' ? '&text=' + encodeURIComponent(this.text.value) : ''}`
      return link
    }
  }
}
</script>

<style lang="css">
.hs-skeleton .v-skeleton-loader__image {
  height: 0;
  padding-bottom: 50%;
}
.hs-skeleton .v-skeleton-loader__heading {
  margin: 10px;
  height: 18px;
}
.hs-combobox .v-select.v-input--dense .v-chip {
  margin: 4px;
}
</style>
