<template>
  <v-card class="mt-5" tile color="grey darken-4">
    <v-subheader class="pt-2">Text hinzufügen</v-subheader>
    <v-container class="py-0 px-4">
      <v-checkbox @change="text = '', $emit('text-show', show, text)" v-model="show" color="blue-grey" label="Text anzeigen" class="mt-1"></v-checkbox>
      <v-textarea @input="$emit('text-change', show, text)" solo rows="4" v-if="show" color="blue-grey" v-model="text" placeholder="Dieser Text wird über dem Grid angezeigt..." auto-grow clearable></v-textarea>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: ['appliedText', 'appliedTextShow'],
  watch: {
    appliedText (value) {
      this.text = value
    },
    appliedTextShow (value) {
      this.show = value
    }
  },
  data: () => ({
    text: '',
    show: false
  })
}
</script>
