<template>
  <v-card class="mx-auto card" :class="{'is-selected': isSelected}" color="blue-grey darken-4" elevation="4" @click="handleClick()">
    <v-checkbox v-model="isSelected" readonly color="blue-grey lighten-4" class="mt-0 pl-2 pt-2" style="position: absolute;z-index:1;"></v-checkbox>
    <v-img gradient="to top left, rgba(30,50,56,.1) 55%, rgba(30,50,56,.95)" lazy-src="placeholder.jpg" :src="project.featuredImage.node.sourceUrl" aspect-ratio="2" class="grey darken-4">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey darken-3"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card-subtitle class="py-2">
     <span
      class="d-block text-truncate"
      style="max-width: 100%;"
    >{{ project.title }}</span>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: {
    project: {
      required: true,
      type: Object
    },
    isSelected: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick () {
      if (!this.isSelected) {
        this.$emit('add-to-selected-projects')
      } else {
        this.$emit('remove-from-selected-projects')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  transition: transform 0.2s ease-out;

  &.is-selected {
  transform:scale(0.92);
  }
}
</style>
