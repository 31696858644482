<template>
  <v-card class="px-5 pt-5" tile color="grey darken-4">
   <slot name="search"></slot>
    <v-row style="max-height: 700px; overflow-y: auto">
      <template v-if="isLoading">
        <v-col v-for="n in 22" :key="n" class="d-flex child-flex pa-2" cols="4" lg="3"><v-skeleton-loader class="hs-skeleton" type="card"></v-skeleton-loader></v-col>
      </template>
      <template v-else>
        <slot name="grid"></slot>
      </template>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'Grid',
  props: ['searchTerms', 'isLoading', 'filteredProjects', 'selectedProjects'],
  data: () => ({
    searchInput: null
  })
}
</script>
